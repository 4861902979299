import { useAccount } from 'wagmi'
import { useState, useEffect, useContext } from 'react'
import multicall, { multicallv2 } from 'utils/multicall'
import IFTieredSaleABI from 'config/abi/IFTieredSale.json'
import CONTRACTS from 'config/constants/contracts'
import { utils } from 'ethers'
import { CompanyContext } from 'contexts/CompanyContext'
import { isAddress } from 'utils'

export const useReferralCodes = () => {
  const { address, isConnected } = useAccount()
  const [userCodeInfo, setUserCodeInfo] = useState({})
  const [userCodeInfoDetail, setUserCodeInfoDetail] = useState({})
  const [userMainCodes, setUserMainCodes] = useState<string[] | []>([])
  const [isLoading, setIsLoading] = useState(false)
  const { brand } = useContext(CompanyContext)
  const IFTieredSaleContracts = CONTRACTS.IFTieredSale[brand] || {}

  const getUserCodes = async (userAddress: string) => {
    const codesPerChain = {}
    let userHasWalletCode = false

    const supportedNetworksForTieredSale = Object.keys(IFTieredSaleContracts)

    const codeDataPromises = supportedNetworksForTieredSale.map(async (network: any) => {
      const saleContractsByChain = Object.keys(IFTieredSaleContracts[network].contracts)

      const multicallParams = saleContractsByChain.map((saleContract) => ({
        address: saleContract,
        name: 'getOwnerPromoCodes',
        params: [userAddress],
      }))

      const codesForChain = await multicall(IFTieredSaleABI, multicallParams, network, {
        returnCallAddress: true,
      })

      const userHasWalletCodeMulticallParams = saleContractsByChain.map((saleContract) => ({
        address: saleContract,
        name: 'validateWalletPromoCode',
        params: [userAddress.toLowerCase()],
      }))

      const userHasWalletCodesForChainRes = await multicall(
        IFTieredSaleABI,
        userHasWalletCodeMulticallParams,
        network,
        {
          returnCallAddress: true,
        },
      )

      const userHasWalletCodesForChain = {}

      userHasWalletCodesForChainRes.forEach((arr: [boolean, string]) => {
        const hasCode = arr[0]
        const saleContractAddress = arr[1]
        if (hasCode) {
          userHasWalletCodesForChain[saleContractAddress] = true
        }
      })

      codesForChain.forEach((userCodesInContract: string[]) => {
        const saleContractAddress = userCodesInContract[userCodesInContract.length - 1]
        const codesForContract = userCodesInContract[0]

        if (!codesPerChain[network]) {
          codesPerChain[network] = {}
        }

        if (userHasWalletCodesForChain[saleContractAddress]) {
          userHasWalletCode = true
          codesPerChain[network][saleContractAddress] = [...codesForContract, userAddress.toLowerCase()]
        } else {
          codesPerChain[network][saleContractAddress] = codesForContract
        }
      })
    })

    await Promise.all(codeDataPromises)

    return { codesPerChain, userHasWalletCode }
  }

  const getUserCodesInfo = async (codesPerChain: any, userAddress: string) => {
    const codeInfoPerContractAggregated = {}
    const codeInfoPerContractDetailed = {}
    const networks = Object.keys(codesPerChain)
    const userMainCodes = []

    const codeInfoPromises = networks.map(async (network: any) => {
      const multicallParams = []
      Object.keys(codesPerChain[network]).forEach((saleContract) =>
        codesPerChain[network][saleContract].forEach((code) => {
          multicallParams.push({
            address: saleContract,
            name: 'promoCodes',
            params: [code],
          })
        }),
      )

      const codeInfoForChain = await multicall(IFTieredSaleABI, multicallParams, network, {
        returnCallAddress: true,
        returnCallParams: true,
      })

      //seperate by contract
      codeInfoForChain.forEach(async (codeInfo: any) => {
        const [code] = codeInfo[codeInfo.length - 1]
        const codeOwner = codeInfo[1]
        const saleContractAddress = codeInfo[codeInfo.length - 2]
        const saleContractsByChain = IFTieredSaleContracts[network]
        const contractInfo = IFTieredSaleContracts[network].contracts[saleContractAddress]

        const walletCodeCommissionRate = contractInfo.defaultWalletCodeCommissionRate || 0

        const promoCodeOwnerEarnings = parseFloat(
          Number(utils.formatUnits(codeInfo[3], contractInfo.paymentTokenDecimals || 18)).toFixed(5),
        )

        const masterOwnerEarnings = parseFloat(
          Number(utils.formatUnits(codeInfo[4], contractInfo.paymentTokenDecimals || 18)).toFixed(5),
        )

        const earnings = codeOwner === userAddress ? promoCodeOwnerEarnings : masterOwnerEarnings

        const paymentTokenSymbol = contractInfo.paymentTokenSymbol

        const totalPurchased = parseFloat(
          Number(utils.formatUnits(codeInfo[5], contractInfo.paymentTokenDecimals || 18)).toFixed(5),
        )

        if (!codeInfoPerContractDetailed[saleContractAddress]) {
          codeInfoPerContractDetailed[saleContractAddress] = []
        }

        if (codeOwner === userAddress || isAddress(code)) {
          userMainCodes.push(code)
        }

        codeInfoPerContractDetailed[saleContractAddress].push({
          code,
          earnings: codeOwner === userAddress ? promoCodeOwnerEarnings : masterOwnerEarnings,
          totalPurchased,
          rate: isAddress(code) ? walletCodeCommissionRate : codeInfo[0],
          paymentTokenSymbol,
        })

        if (!codeInfoPerContractAggregated[saleContractAddress]) {
          codeInfoPerContractAggregated[saleContractAddress] = {
            contractAddress: saleContractAddress,
            chainId: network,
            chainName: saleContractsByChain.chainName,
            rate: isAddress(code) ? walletCodeCommissionRate : codeInfo[0],
            paymentTokenSymbol,
            earnings,
            walletCodeEarnings: isAddress(code) ? promoCodeOwnerEarnings : 0,
            kolCodeEarnings: isAddress(code)
              ? 0
              : codeOwner === userAddress
              ? promoCodeOwnerEarnings
              : masterOwnerEarnings,
            totalPurchased,
          }
        } else {
          codeInfoPerContractAggregated[saleContractAddress] = {
            ...codeInfoPerContractAggregated[saleContractAddress],
            earnings: codeInfoPerContractAggregated[saleContractAddress].earnings + earnings,
            walletCodeEarnings: isAddress(code)
              ? codeInfoPerContractAggregated[saleContractAddress].walletCodeEarnings + promoCodeOwnerEarnings
              : codeInfoPerContractAggregated[saleContractAddress].walletCodeEarnings,

            kolCodeEarnings: isAddress(code)
              ? codeInfoPerContractAggregated[saleContractAddress].kolCodeEarnings
              : codeOwner === userAddress
              ? codeInfoPerContractAggregated[saleContractAddress].kolCodeEarnings + promoCodeOwnerEarnings
              : codeInfoPerContractAggregated[saleContractAddress].kolCodeEarnings + masterOwnerEarnings,
            totalPurchased: codeInfoPerContractAggregated[saleContractAddress].totalPurchased + totalPurchased,
          }
        }
      })
    })

    await Promise.all(codeInfoPromises)
    return { codeInfoPerContractAggregated, codeInfoPerContractDetailed, userMainCodes }
  }

  const handleGetUserCodesData = async (userAddress: string) => {
    try {
      const { codesPerChain } = await getUserCodes(userAddress)
      const { codeInfoPerContractAggregated, codeInfoPerContractDetailed, userMainCodes } = await getUserCodesInfo(
        codesPerChain,
        userAddress,
      )

      return { codeInfoPerContractAggregated, codeInfoPerContractDetailed, userMainCodes }
    } catch (e) {
      console.log(e)
      return {
        codeInfoPerContractAggregated: {},
        codeInfoPerContractDetailed: {},
        userMainCodes: [],
      }
    }
  }

  const fetchData = async () => {
    try {
      setIsLoading(true)
      setUserCodeInfo({})
      setUserCodeInfoDetail({})

      const {
        codeInfoPerContractAggregated,
        codeInfoPerContractDetailed,
        userMainCodes: userPrimaryCodes,
      } = await handleGetUserCodesData(address)

      setUserCodeInfo(codeInfoPerContractAggregated)
      setUserCodeInfoDetail(codeInfoPerContractDetailed)

      setUserMainCodes(userPrimaryCodes)

      setIsLoading(false)
    } catch (e) {
      console.log(e)
      setIsLoading(false)
      setUserCodeInfo({})
      setUserCodeInfoDetail({})
    }
  }

  useEffect(() => {
    if (!address) {
      setUserCodeInfo({})
      setUserCodeInfoDetail({})
      return
    }

    fetchData()
  }, [address])

  return {
    userMainCodes,
    userCodeInfoDetail,
    userCodeInfo,
    isLoading,
    refetchCodeData: fetchData,
  }
}
